import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import AudioPlayer from "../../../components/audio-player"
import Constrain from "../../../components/constrain"
import Breadcrumb from "../../../components/breadcrumb"
import Paragraph from "../../../components/paragraph"
import Grid from "../../../components/grid"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

import LeichteSpracheNext from "../../../content/shared/leichte-sprache-next"

const LeichteSpracheBibliothekar = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheBibliothekarQuery {
      bibliothek: file(
        relativePath: { eq: "leichte-sprache/bibliothek-bad-homburg.jpg" }
      ) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Hölderlin darf als Bibliothekar arbeiten"
        description="Isaac von Sinclair hat eine Arbeit für Hölderlin gefunden. Hölderlin kann als Bibliothekar arbeiten. Ein Bibliothekar arbeitet in einer Bibliothek. Eine Bibliothek ist eine Bücherei. Dort gibt es sehr viele Bücher. Der Bibliothekar arbeitet mit diesen Büchern."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Über Hölderlin",
                link: "/leichte-sprache/ueber-hoelderlin",
              },
              {
                title: "Bibliothekar",
                link: "/leichte-sprache/ueber-hoelderlin/bibliothekar",
              },
            ]}
          />
          <PageTitle>Hölderlin darf als Bibliothekar arbeiten</PageTitle>
          <Constrain align="left">
            <AudioPlayer src="leichte-sprache/07-bibliothekar" />
          </Constrain>
          <Paragraph dropcap={true}>
            Isaac von Sinclair hat eine Arbeit für Hölderlin gefunden. <br />
            Hölderlin kann als Bibliothekar arbeiten. <br />
            Ein Bibliothekar arbeitet in einer Bibliothek. <br />
            Eine Bibliothek ist eine Bücherei. <br />
            Dort gibt es sehr viele Bücher. <br />
            Der Bibliothekar arbeitet mit diesen Büchern.
          </Paragraph>

          <Grid columns={[1, 2]} alignY="center">
            <Image
              image={data.bibliothek.childImageSharp.gatsbyImageData}
              caption="In diesem Gebäude war die Bibliothek und die Wohnung vom Landgrafen von Hessen-Homburg."
              attribution="Barbara Dölemeyer"
              alt="Ein hellgelbes Gebäude mit grünen Fensterläden. Dahinter: Ein Aussichtsturm vom Homburger Schloss."
            />
            <Paragraph>
              Hölderlin arbeitet also in einer Bibliothek. <br />
              Die Bibliothek gehört dem Land∙grafen von Hessen-Homburg. <br />
              Das Gehalt für Hölderlin bezahlt aber Isaac von Sinclair. <br />
              Er will seinem Freund Hölderlin helfen. <br />
              Hölderlin freut sich, <br />
              dass er mit Büchern arbeiten darf. <br />
              Man weiß aber nicht: <br />
              Hat er dort wirklich gearbeitet?
            </Paragraph>
          </Grid>

          <Constrain>
            <Paragraph>
              In Bad Homburg untersucht ein Arzt Hölderlin. <br />
              Er sagt: <br />
              Hölderlin ist krank. <br />
              Er ist geistes∙krank. <br />
              Hölderlin lebt 2 Jahre in Homburg. <br />
              Dann sagt sein Freund Sinclair: <br />
              Ich kann nicht mehr für Hölderlin sorgen. <br />
              Er ist zu krank. <br />
              Deshalb kommt Hölderlin nach Tübingen ins Kranken∙haus. <br />
              Hölderlin will nicht ins Kranken∙haus. <br />
              Aber er muss trotzdem dort hin.
            </Paragraph>
          </Constrain>
        </Stack>
        <LeichteSpracheNext variant="krank" />
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheBibliothekar
